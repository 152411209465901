<template>
  <div >
      <v-card class="align-center" flat>

        <v-card-title>
          Search Project
        </v-card-title>
        <v-card-text>
          
          <v-form
                @submit="searchProject($event)"
            >
          <v-text-field
            outlined
            dense
            v-model="searchQuery"
            clearable
            :append-icon="icons.mdiSendOutline"            
            @click:append="searchProject"
            @click:append-outer="searchProject"
            @click:clear="searchProject"
          >

          </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text class="">
          <v-card
            class="d-flex flex-wrap"
            color="grey lighten-2"
            flat
            tile
          >
            <v-card
              v-for="(item, idx) in surveys"
              :key="idx"
              width="250"
              class="ma-1 step-cursor"
              outlined
              @click.native="projectClick(item)"
            >
            
              <v-list-item three-line>
                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                  class="mr-0"
                >
                <v-avatar
                  size="60"
                  :color="item.color || 'primary'"
                  rounded
                >
                  <span
                    class="font-weight-semibold white--text"
                  >{{ item.job_number }}</span>
                </v-avatar>

                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="text-md mb-2">
                    {{ item.project }}
                  </div>
                  
                  <div class="text-md mb-2">
                  Market:
                  </div>
                  <v-list-item-title
                  class="font-weight-bold">
                    {{ item.country.text }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-icon
                small
                color="success"
                v-if="item.questionnaire"
              >
                {{ icons.mdiCheck }}
              </v-icon>
              </v-list-item>
            </v-card>
            <v-btn 
                class="ma-2 white--text"
                color="secondary"
                small
                text
                v-show="isLoadMore"
                @click="manualLoad"
            >
            
            </v-btn>
            
            

          <infinite-loading 
            ref="infiniteLoading"
            @infinite="loadItems"
          >
          <div slot="spinner"></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        
          </infinite-loading>
          </v-card>
        </v-card-text>
      </v-card>

      <questionnaire-options-dialog
        :isQuestionnaireOptionsDialogOpen.sync="questionnaireOptionIsOpen"
        :project-data="survey"
        @build="buildQuestionnaire"
        @import="importQuestionnaire"
      ></questionnaire-options-dialog>
  </div>
</template>

<script>
import { onUnmounted, onMounted, ref, watch, getCurrentInstance } from '@vue/composition-api'
import store from '@/store'

import projectStoreModule from '@/views/apps/project/projectStoreModule'
import surveyStoreModule from './surveyStoreModule'
import useProjectsList from '@/views/apps/project/project-list/useProjectsList'
import { foundryRequest } from '@/config/authConfig'
import { stringToHslColor, useRouter } from '@core/utils'
import {
  mdiSendOutline,
  mdiCheck
} from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'
import QuestionnaireOptionsDialog from './QuestionnaireOptionsDialog.vue'

export default {
  components:{
    InfiniteLoading,
    QuestionnaireOptionsDialog
  },
  setup() {

    //$


    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'
    const SURVEY_APP_STORE_MODULE_NAME = 'app-survey'

    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)
    if (!store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.registerModule(SURVEY_APP_STORE_MODULE_NAME, surveyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
      if (store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.unregisterModule(SURVEY_APP_STORE_MODULE_NAME)
    })

    const {
      loading,
    } = useProjectsList()
    
    const searchQuery = ref('')

    const surveys = ref([])
    const survey = ref({})
    const page = ref(1)
    const isLoadMore = ref(true)

    const questionnaireOptionIsOpen = ref(false)

    const resetSurveys = () => {
      vm.$refs.infiniteLoading.stateChanger.reset()
      isLoadMore.value = true
      page.value = 1
      surveys.value = []
    }

    const searchProject = (event) => {
      if (event) {
        event.preventDefault()
      }
      loading.value = true
      resetSurveys()
      manualLoad()
      // fetchProjects()
    }

    const fetchProjects = async (state= {
      loaded: () => {},
      complete: () => {}
    }) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      if (searchQuery.value === null) searchQuery.value = '';
      await store
        .dispatch('app-project/fetchProjects', {
          search: searchQuery.value,
          fields: ['job_number', 'job_name', 'countries', 'service_type', 'project_type'],
          page:page.value
        })
        .then(response => {
          const { results, total_pages } = response.data
          for (const project of results){
            for (const country of project.countries){
              const questionnaire = {
                job_number: project.job_number,
                project_type: project.project_type,
                project: `${project.job_name}`,
                country: {
                  text: country.country_detail.name,
                  value: country.country_detail.code
                },
                questionnaire:country.questionnaire,
                color: stringToHslColor(project.job_name),
                templates: project.service_type.survey_templates ? project.service_type.survey_templates : []
              }
              surveys.value.push(questionnaire)
            }
          }

          // remove loading state
          loading.value = false
          
          if (page.value < total_pages){
            
            state.loaded()
            page.value += 1
          } else {
            isLoadMore.value = false
            state.complete()
          }

        })
        .catch(error => {
          console.log(error)
        })
    }

    const loadItems = async ($state) => {
      await fetchProjects($state)
    }

    const manualLoad = () => {
      vm.$nextTick(() => {
        vm.$refs.infiniteLoading.attemptLoad()
      })
      
    }

    const projectClick = (project) => {
      survey.value = project
      if (!project.questionnaire) {
        questionnaireOptionIsOpen.value = true
      } else {
        buildQuestionnaire(false)
      }
    }

    const buildQuestionnaire = (isNew=true, templateId=null) => {
      router.push({name: 'survey-project-view', params: {id: survey.value.job_number, country: survey.value.country.value, new:isNew, templateId}})
    }

    const importQuestionnaire = () => {
      
    }

    onMounted(() => {
      vm.$nextTick(() => {
        vm.$refs.infiniteLoading.attemptLoad()
      })
    })


    return {
      surveys,
      survey,
      searchQuery,
      searchProject,
      questionnaireOptionIsOpen,
      buildQuestionnaire,
      importQuestionnaire,
      projectClick,
      icons: {
        mdiSendOutline,
        mdiCheck
      },
      loadItems,
      manualLoad,
      isLoadMore
    }
  },
}
</script>

<style lang="css" scoped>

.step-cursor {
    cursor: pointer;
}

</style>